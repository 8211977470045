import { defineAsyncComponent } from 'vue'

import {
  RESOLUTION_OPTION_TYPE,
  type Resolution,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/common'
import type {
  OrderlineTimeline,
  StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

import { getActiveTimelineStep } from '../../utils/getActiveTimelineStep'
import { returnConfirmationPageConfig } from '../ReturnConfirmationPage.config'

interface useReturnConfirmationVariantReturnValue {
  activeStep?: StepType | ''
  variantError?: Error
  variant?: ReturnType<typeof defineAsyncComponent>
}

export function useReturnConfirmationVariant({
  orderlineTimeline,
  resolution,
}: {
  orderlineTimeline?: Array<OrderlineTimeline>
  resolution?: Resolution
}): useReturnConfirmationVariantReturnValue {
  const { logFeatureError } = useSalesCustomerCareLogger()
  if (orderlineTimeline && resolution) {
    try {
      const orderlineState = resolution.problemContext?.orderline?.state

      const activeStep = getActiveTimelineStep({
        orderlineState,
        orderlineTimeline,
      })

      if (isEmpty(activeStep)) {
        logFeatureError({
          errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.returnConfirmationPage} Active orderline timeline step unknown`,
          errorDetail: {
            active_timeline_step: activeStep,
            orderline_id: resolution?.problemContext?.orderline?.id,
            timeline: orderlineTimeline,
          },
          featureName:
            'return_confirmation_page_active_orderline_timeline_unknown',
        })

        throw new Error('active orderline timeline step unknown')
      }

      const variant =
        returnConfirmationPageConfig[resolution?.resolutionOption?.type]

      if (!variant) {
        logFeatureError({
          errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.returnConfirmationPage} No variant exists for this resolution type`,
          errorDetail: {
            allowed_resolution_types: [
              RESOLUTION_OPTION_TYPE.factoryRepair,
              RESOLUTION_OPTION_TYPE.refund,
              RESOLUTION_OPTION_TYPE.repairReplace,
              RESOLUTION_OPTION_TYPE.sellerRepair,
              RESOLUTION_OPTION_TYPE.sellerReplace,
            ],
            current_resolution_type: resolution?.resolutionOption?.type,
            orderline_id: resolution?.problemContext?.orderline?.id,
          },
          featureName:
            'return_confirmation_page_no_variant_match_for_resolution_type',
        })

        throw new Error('No variant exists for this resolution type')
      }

      const { allowedOrderlineTimelineStep, component } = variant

      if (!allowedOrderlineTimelineStep.includes(activeStep)) {
        logFeatureError({
          errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.returnConfirmationPage} No variant exists for this resolution type and orderline active timeline step`,
          errorDetail: {
            allowed_timeline_steps: allowedOrderlineTimelineStep,
            active_timeline_step: activeStep,
            current_resolution_type: resolution?.resolutionOption?.type,
            orderline_id: resolution?.problemContext?.orderline?.id,
          },
          featureName:
            'return_confirmation_page_active_timeline_step_not_allowed_on_this_resolution_type',
        })

        throw new Error(
          'No variant exists for this resolution type and orderline active timeline step',
        )
      }

      return {
        activeStep,
        variant: component,
      }
    } catch (error) {
      return {
        variantError: error as Error,
      }
    }
  }

  return {}
}
