import { defineAsyncComponent } from 'vue'

import {
  RESOLUTION_OPTION_TYPE,
  type ResolutionOptionType,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/common'
import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'

export type ReturnConfirmationConfig = Partial<
  Record<
    ResolutionOptionType,
    {
      allowedOrderlineTimelineStep: Array<Partial<StepType>>
      component: ReturnType<typeof defineAsyncComponent>
    }
  >
>

export const returnConfirmationPageConfig: ReturnConfirmationConfig = {
  [RESOLUTION_OPTION_TYPE.factoryRepair]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () =>
        import(
          './components/FactoryRepairConfirmation/FactoryRepairConfirmation.vue'
        ),
    ),
  },
  [RESOLUTION_OPTION_TYPE.refund]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
      ORDERLINE_TIMELINE_STEP_TYPE.validation,
    ],
    component: defineAsyncComponent(
      () => import('./components/RefundConfirmation/RefundConfirmation.vue'),
    ),
  },
  [RESOLUTION_OPTION_TYPE.sellerRepair]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () => import('./components/RepairConfirmation/RepairConfirmation.vue'),
    ),
  },
  [RESOLUTION_OPTION_TYPE.sellerReplace]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () => import('./components/ReplaceConfirmation/ReplaceConfirmation.vue'),
    ),
  },
  [RESOLUTION_OPTION_TYPE.repairReplace]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () =>
        import(
          './components/RepairReplaceConfirmation/RepairReplaceConfirmation.vue'
        ),
    ),
  },
}
